.outer {
  display: flex;
  height: 100vh;
  align-items: center;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  margin: 0 auto;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 20px 20px rgb(220, 220, 220);
}

.login-button {
  background-color: #06adf5;
  width: 200px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #60c4ee;
  }
}

.container-prompt {
  color: rgb(168, 168, 168);
  font-size: 14px;
}
