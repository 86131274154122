@tailwind base;
@tailwind components;
@tailwind utilities;


body{ 
  font-family: Lato;
  @apply text-blueDark m-0 bg-greyLighter
}

h1,h2,h3,h4,h5,h6{
  @apply text-blueDark
}

h1{ font-weight: 900; font-size: 36px; line-height: 45px; letter-spacing: 0.5px;}

h2,h3,h4,h5,h6,
.h2,.h3,.h4,.h5,.h6{ font-weight: 700; letter-spacing: 0.5px;}

.h2, h2{ font-size: 32px; line-height: 45px;}
.h3, h3{ font-size: 28px; line-height: 35px;}
.h4, h4{ font-size: 24px; line-height: 30px;}
.h5, h5{ font-size: 21px; line-height: 26px;}
.h6, h6{ font-size: 18px; line-height: 23px;}

.s1{ font-size: 16px; font-weight: bold; line-height: 27px; letter-spacing: 0px;}

@media (min-width: 769px) { 
  h1{font-size: 48px; line-height: 60px; letter-spacing: 1px;}
  h2{ font-size: 48px; line-height: 60px; letter-spacing: 1px;}
  h3{ font-size: 40px; line-height: 50px; letter-spacing: 1px;}
  h4{ font-size: 32px; line-height: 40px;}
  h5{ font-size: 26px; line-height: 32px;}
  h6{ font-size: 21px; line-height: 26px;}

  p{ font-size: 21px; line-height: 32px; letter-spacing: 0.5px;}
  .p2{ font-size: 18px; line-height: 27px; letter-spacing: 0.5px;}

  .s1{ font-size: 18px; letter-spacing: 1px;}
  .s2{ font-size: 16px; line-height: 24px; letter-spacing: 0.5px;}
  .s3{ font-size: 40px; line-height: 56px; letter-spacing: 1px;}
}

@media (min-width: 993px) { 
  h1{ font-size: 60px; line-height: 75px;}
}

div.Content > div,
section.Content > div{
  max-width: 1550px;
  @apply m-auto pl-4 pr-4 xxl:pl-0 xxl:pr-0
}

.link{ cursor: pointer;}
